import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Container from '../components/Container'
import ContainerHero from '../components/ContainerHero'
import ContainerContent from '../components/ContainerContent'
import ContentHomepage from '../components/ContentHomepage'
import HeroHome from '../components/HeroHome'
import Newsletter from '../components/NewsletterPopup.js'

const Homepage = () => {
  return (
    <Layout>
      <Newsletter />
      <SEO
        title="▷ IT Dienstleister & EDV Betreuung: Mac & Windows | EXTENDIT"
        description="Professionelle IT-Dienstleistungen und IT-Betreuung für Mac & Windows in Wien, Niederösterreich und dem Burgenland ❱❱ EXTENDIT kennenlernen!"
      />
      <Container>
        <ContainerHero>
          <HeroHome />
        </ContainerHero>
        <ContainerContent>
          <ContentHomepage />
        </ContainerContent>
      </Container>
    </Layout>
  )
}

export default Homepage

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
require('swiper/swiper.scss')

SwiperCore.use([Autoplay])

const Wrapper = styled.div`
  width: 100%;
  padding: 30px 0;
  @media (min-width: ${props => props.theme.responsive.small}) {
    padding: 60px 0;
  }

  .swiper-slide{
    overflow: hidden;
  }

  .swiper-container {
    width: 100%;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: ${props => props.theme.colors.text};
  }
`

const Referenz = styled.div`
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  @media (min-width: ${props => props.theme.responsive.medium}) {
    flex-direction: row;
  }

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    padding: 0 30px 0 15px;
  }

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    padding: 0 10px;
  }
`

const ImageWrapper = styled.div`
  padding-right: 20px;

  .gatsby-image-wrapper {
    max-width: 150px;
    height: 150px;
    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      max-width: 100px;
      height: 100px;
    }
  }

  img {
    padding: 5px;
    width: 100%;
    border: 2px solid #000;
    border-radius: 999px;
    height: auto;
    object-fit: contain !important;
  }
`

const ContentWrapper = styled.div`
  padding-left: 20px;
  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    padding-left: 0px;
  }
`

const Number = styled.div`
  font-size: ${props => props.theme.fontSizes[5]}px;
  font-weight: bold;
  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    font-size: ${props => props.theme.fontSizes[4]}px;
  }
  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    padding-top: 20px;
    font-size: ${props => props.theme.fontSizes[1]}px;
  }
`

const Name = styled.div`
  padding-top: 15px;
  width: 100%;
  font-size: ${props => props.theme.fontSizes[5]}px;
  border-top: 2px solid #000;
  line-height: 1.2;
  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    padding-top: 20px;
    font-size: ${props => props.theme.fontSizes[2]}px;
    line-height: 1.5em;
  }

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    border-top: none;
    padding-top: 0;
  }
`

const Position = styled.div`
  padding-top: 0px;
  width: 100%;
  font-size: ${props => props.theme.fontSizes[1]}px;

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    font-size: ${props => props.theme.fontSizes[1]}px;
    line-height: 1.5em;
    padding-top: 5px;
  }
`

const Company = styled.div`
  padding: 20px 0;
  font-size: ${props => props.theme.fontSizes[5]}px;
  margin-bottom: 20px;
  border-bottom: 2px solid #000;

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    font-size: ${props => props.theme.fontSizes[2]}px;
    line-height: 1.5em;
  }

  > div {
    border: 2px solid ${props => props.theme.colors.red};
    width: auto;
    display: inline-block;
    padding: 7px 20px;
    border-radius: 999px;
  }
`

const Zitat = styled.div`
  width: 100%;
  font-size: ${props => props.theme.fontSizes[1]}px;
`

const Referenzen = props => {
  const data = useStaticQuery(graphql`
    {
      allContentfulCustomers {
        edges {
          node {
            contentful_id
            name
            position
            zitat {
              internal {
                content
              }
            }
            image {
              fluid(
                maxWidth: 150
                maxHeight: 150
                resizingBehavior: PAD
                cropFocus: CENTER
              ) {
                ...GatsbyContentfulFluid_withWebp
              }
              title
            }
            companyName
          }
        }
      }
    }
  `)
  const refs = data.allContentfulCustomers.edges
  return (
    <Wrapper>
      <Swiper
        spaceBetween={50}
        slidesPerView={2}
        speed={9000}
        autoplay={{
          delay: 5,
          disableOnInteraction: true,
          waitForTransition: true,
        }}
        loop
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1050: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
        }}
      >
        {refs.map(({ node: ref }, index) => (
          <SwiperSlide key={ref.contentful_id} virtualIndex={index}>
            <Referenz>
              <ImageWrapper>
                <Img
                  fluid={ref.image.fluid}
                  alt={ref.image.title}
                  css={css`
                    height: 150px;
                    width: 150px;
                  `}
                />
              </ImageWrapper>
              <ContentWrapper>
                <Number>0{index+1}</Number>
                <Name>{ref.name}</Name>
                <Position>{ref.position}</Position>
                <Company>
                  <div>{ref.companyName}</div>
                </Company>
                <Zitat>{ref.zitat.internal.content}</Zitat>
              </ContentWrapper>
            </Referenz>
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  )
}

export default Referenzen

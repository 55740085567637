import React from 'react'
import styled from '@emotion/styled'
import { Styled } from 'theme-ui'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 30px 15px 10px;
  @media (min-width: ${props => props.theme.responsive.medium}) {
    width: 120px;
    padding: 0;
    justify-content: center;
    align-items: flex-end;
    border-right: 2px solid #000;
    position: relative;
    h2 {
      writing-mode: tb-rl;
      transform: rotate(180deg);
      padding: 30px 4px;
      font-size: ${props => props.theme.fontSizes[5]}px;
    }
  }

  @media (min-width: ${props => props.theme.responsive.large}) {
    h2 {
      font-size: ${props => props.theme.fontSizes[7]}px;
    }
  }
`

const ContainerLeftTitle = props => {
  return (
    <Wrapper>
      <Styled.h2>{props.children}</Styled.h2>
    </Wrapper>
  )
}

export default ContainerLeftTitle

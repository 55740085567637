/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { graphql, useStaticQuery, Link } from 'gatsby'
import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
require('swiper/swiper.scss')

SwiperCore.use([Autoplay])

const Wrapper = styled.div`
  flex: 1 1 auto;
  font-size: ${props => props.theme.fontSizes[5]}px;
  overflow: hidden;
  position: relative;
  min-height: 300px;
  min-width: 100%;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;

  @media (min-width: ${props => props.theme.responsive.medium}) {
    max-width: 520px;
    min-width: 35%;
    border-top: none;
    border-bottom: none;
    border-right: 2px solid #000;
  }

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    font-size: ${props => props.theme.fontSizes[3]}px;
  }

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    font-size: ${props => props.theme.fontSizes[1]}px;
  }

  @media (max-width: ${props => props.theme.responsive.smallMax}) {
    font-size: ${props => props.theme.fontSizes[1]}px;
  }

  & a{
    display: inline-block;
    width: 100%;
  }

  & .swiper-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & .swiper-wrapper {
    display: flex;
    flex-direction: column;
  }

  & .swiper-slide {
    height: auto !important;
  }

`

const Post = styled.div`
  border-bottom: 2px solid #000;
  width: 100%;
  padding: 10px 15px;
  white-space: nowrap;
`

const PostSlider = props => {
  const data = useStaticQuery(graphql`
    {
      allContentfulPost(sort: { order: DESC, fields: publishDate }, limit: 15) {
        edges {
          node {
            title
            publishDate(formatString: "MM/YY")
            slug
            id
          }
        }
      }
    }
  `)

  let truncate = str => {
    return str.length > 29 ? str.substring(0, 29) + '...' : str
  }

  const posts = data.allContentfulPost.edges
  return (
    <Wrapper>
      <Swiper
        direction={'vertical'}
        spaceBetween={0}
        slidesPerView={15}
        speed={5000}
        autoplay={{
          delay: 1,
          disableOnInteraction: true,
          waitForTransition: false,
          reverseDirection: false,
        }}
        loop

      >
        {posts.map(({ node }, index) => (
          <SwiperSlide key={'sslide'+node.id} virtualIndex={index}>
            <Link to={`/news/${node.slug}/`}>
              <Post>
                <div>{truncate(`${node.title}`)}</div>
              </Post>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  )
}

export default PostSlider

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
require('swiper/swiper.scss')

SwiperCore.use([Autoplay])

const Wrapper = styled.div`
  border-top: 2px solid #000;
  width: 100%;
  padding: 30px 0;
  @media (min-width: ${props => props.theme.responsive.small}) {
    padding: 60px 0;
  }

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-container {
    width: 100%;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: ${props => props.theme.colors.text};
  }
`

const Partner = styled.div`
  padding: 0 30px;
`

const ImageWrapper = styled.div`
  max-width: 300px;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
  }
`

const Partners = props => {
  const data = useStaticQuery(graphql`
    {
      allContentfulPartners {
        edges {
          node {
            contentful_id
            logo {
              fixed(
                height: 120
                width: 120
                resizingBehavior: PAD
                quality: 90
                cropFocus: CENTER
              ) {
                ...GatsbyContentfulFixed_withWebp
              }
              title
            }
          }
        }
      }
    }
  `)
  const partners = data.allContentfulPartners.edges
  return (
    <Wrapper>
      <Swiper
        spaceBetween={50}
        slidesPerView={5}
        speed={9000}
        autoplay={{
          delay: 5,
          disableOnInteraction: true,
          waitForTransition: true,
          reverseDirection: true,
        }}
        loop
        breakpoints={{
          320: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          680: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1050: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
        }}
      >
        {partners.map(({ node: partner }, index) => (
          <SwiperSlide key={partner.contentful_id} virtualIndex={index}>
            <Partner>
              <ImageWrapper>
                <Img
                  fixed={partner.logo.fixed}
                  alt={partner.logo.title}
                  css={css`
                    max-height: 120px;
                  `}
                />
              </ImageWrapper>
            </Partner>
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  )
}

export default Partners
